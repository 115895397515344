import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import OurPricing from "../HomePage/OurPricing";
import "../../../theme/css/Service.css";
import Innovation from "./Innovation";
import Aos from "aos";
import "aos/dist/aos.css";
export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="otherPageBanner servicesBanner">
        <div className="text-center title" data-aos="fade-down">
          <h2>OUR SERVICE</h2>
          <h6>We help our clients startup business</h6>
        </div>
      </div>
      <AmazingExpertise />
      <OurPricing />
      <Innovation />
    </>
  );
}
