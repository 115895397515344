import React,{useEffect} from "react";
import "../../../theme/css/Planing.css";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Chiffres() {
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
    return(
        <>
         <div>
          <h4 className="keytitle text-center">Quelques chiffres</h4>
        </div>
        <div className="container">
        <div className="planingIconMainDiv">
          <Row data-aos="fade-up">
            <Col lg={3} xl={3} md={3} sm={6}>
              <div className="planingIcon">
                <i className="fas fa-solid fa-users"></i>
                <h2>3500</h2>
                <h6>cadres formés par an</h6>
              </div>
            </Col>
            <Col lg={3} xl={3} md={3} sm={6}>
              <div className="planingIcon">
                <i className="fa fa-globe"></i>
                <h2>6</h2>
                <h6>campus en France et dans le monde</h6>
              </div>
            </Col>
            <Col lg={3} xl={3} md={3} sm={6}>
              <div className="planingIcon">
                <i className="fas fa-donate"></i>
                <h2> 1 </h2>
                <h6> centre de formation au coeur de Paris </h6>
              </div>
            </Col>
            <Col lg={3} xl={3} md={3} sm={6}>
              <div className="planingIcon">
                <i className="fas fa-solid fa-chart-bar"></i>
                <h2>89 % </h2>
                <h6> de nos participants recommandent nos formations </h6>
              </div>
            </Col>
          </Row>
        </div>
      </div>
        </>
    )

}