import React, {  useEffect } from "react";
import Slider from "react-slick";
import { Card, CardImg, Col ,Row,Container,} from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import profile from "../../../assets/Images/Team/team1.jpeg";

const settings = {
  dots: false,
  aroow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function OurTeam() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container" id="formateur">
        <div className="my-5">
          <h2 className="nosformateurs text-center">Notre Formateur</h2>
        </div>
        <Row className="justify-content-center mt-3 mb-5">
              <Col xl={4} lg={4} md={4}>
              <div class="bimg">
                <img src={profile} className='card-img img-fluid mx-auto' alt='Nassef Hmimda' />
              </div>
              </Col>
              <Col xl={6} lg={6} md={6}>
                <h2 className="nameprof">Olivier Gault</h2>
                <span>Responsable pédagogique principal</span>
                <hr className="my-5"></hr>
                <p>
                Master Black Belt, 30 ans d’expérience à des postes de 
                management dans le domaine de la Qualité, la 
                performance, Six Sigma, la gestion du changement 
                organisationnel et le Service-Après-Vente.
                Responsable Pédagogique en Excellence Opérationnelle 
                chez CentraleSupélec Exed
                </p>
                <div className="text-end">
                <a href="https://fr.linkedin.com/in/olivier-gault-1a17a415" className="btn-envoyer w-80 showmore ourSurvice">Voir plus</a>
                </div>
              </Col>
        </Row>
       
      </div>
    </>
  );
}
