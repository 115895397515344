import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
export default function OurPricing() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="ourPricing">
          <div className="titleFont">
            <h3>Our Pricing</h3>
            <h2>Check Our Pricing</h2>
          </div>
          <div className="ourPricingCard text-center">
            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                <div className="featuresCard" data-aos="flip-left">
                  <h5>Free</h5>
                  <h6>Start From</h6>
                  <h2>$0.00</h2>
                  <ul className="list-unstyled">
                    <li>10 GB Storage</li>
                    <li>3 GB File Upload</li>
                    <li>Unlimited Bandwidth</li>
                    <li>1 Domain</li>
                    <li>24\7 Support</li>
                  </ul>
                  <button className="homeBannerBtn ourSurvice">
                    <Link to="/planing"> Get Started</Link>
                  </button>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                <div className="featuresCard" data-aos="flip-left">
                  <h5>Popular</h5>
                  <h6>Start From</h6>
                  <h2>
                    $49.99
                    <span>$90.00</span>
                  </h2>
                  <ul className="list-unstyled">
                    <li>Unlimited Storage</li>
                    <li>9 GB File Upload</li>
                    <li>Unlimited Bandwidth</li>
                    <li>3 Domain</li>
                    <li>24\7 Support</li>
                  </ul>
                  <button className="homeBannerBtn ourSurvice">
                    <Link to="/planing"> Get Started</Link>
                  </button>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                <div className="featuresCard" data-aos="flip-left">
                  <h5>Professional</h5>
                  <h6>Start From</h6>
                  <h2>
                    $99.99
                    <span>$199.00</span>
                  </h2>
                  <ul className="list-unstyled">
                    <li>Unlimited Storage</li>
                    <li>12 GB File Upload</li>
                    <li>Unlimited Bandwidth</li>
                    <li>9 Domain</li>
                    <li>24\7 Support</li>
                  </ul>
                  <button className="homeBannerBtn ourSurvice">
                    <Link to="/planing"> Get Started</Link>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
