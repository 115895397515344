import React, { useState } from 'react';
import "../../../../src/theme/css/HomePage.css";
import MainBannerImg from "../../../assets/Images/Banner/background.jpg";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";


class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        errorfullname: '',
        errorphone: '',
        erroremail: '',
        errorfonction: '',
        errorville: '',
        success:''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({errorfullname: ''});
    this.setState({errorphone: ''});
    this.setState({erroremail: ''});
    this.setState({errorfonction: ''});
    this.setState({errorville: ''});

    //Validation 
    if(target.nomcomplet.value ==''){
      return this.setState({errorfullname: 'nom complet est obligatoire'});
    }
    if(target.phone.value ==''){
      return this.setState({errorphone: 'téléphone est obligatoire'});
    }
    if(target.email.value ==''){
      return this.setState({erroremail: 'email est obligatoire'});
    }
    if(target.fonction.value ==''){
      return this.setState({errorfonction: 'fonction est obligatoire'});
    }
    if(target.ville.value ==''){
      return this.setState({errorville: 'Ville est obligatoire'});
    }
      const lead={
        'fullname':target.nomcomplet.value,
        'phone':target.phone.value,
        'email':target.email.value,
        'fonction':target.fonction.value,
        'ville':target.ville.value,
      } 
     this.setState({success: 'Merci de nous avoir contacté, nous avons bien reçu vos informations'});
     target.reset();
     window && window.dataLayer && window.dataLayer.push({
      'event':'form_submit_success',
      'event_id': Math.random().toString(36).substr(2, 9) + '' + Math.random().toString(36).substr(2, 9) +'' + Math.random().toString(36).substr(2, 9),
  });

    //  window.dataLayer = window.dataLayer || [];
    //   dataLayer.push({
    //       'event':'form_submit_success',
    //       'event_id': Math.random().toString(36).substr(2, 9) + '' + Math.random().toString(36).substr(2, 9) +'' + Math.random().toString(36).substr(2, 9),
    //   })
     //  console.log(this.state.success)
      // console.log(lead)
      
  }

  render() {
    return (
      <div className="mainBannerDiv">
          <img className="img-fluid mx-auto w-100" src={MainBannerImg} alt="BannerImage" />
            <form action="" method="POST" onSubmit={this.handleSubmit}>
          <div className="bannerContain formContain">
          <Container>
          <Row className="Footerbox">
              <Col xl={12} lg={12} md={12} className="text-center formContaintitle">
                <h2 className="text-uppercase">S'inscrire</h2>
              </Col>
            </Row>
            {this.state.success
                  ? <div className='text-success px-5'> {this.state.success} </div>
                  :
                  ''
                }
            <Row className="Footerbox">
              <Col xl={12} lg={12} md={12}>
                <input placeholder="Nom & Prénom" autoComplete="false" name="nomcomplet"/>
                {this.state.errorfullname
                  ? <span className='text-danger px-5'> {this.state.errorfullname} </span>
                  : ''
                }
              </Col>
            </Row>
            <Row className="Footerbox">
              <Col xl={12} lg={12} md={12}>
                <input placeholder="Téléphone" autoComplete="false" name="phone"/>
                {this.state.errorphone
                  ? <span className='text-danger px-5'> {this.state.errorphone} </span>
                  : ''
                }
              </Col>
              </Row>
             <Row className="Footerbox">
              <Col xl={12} lg={12} md={12}>
              <input placeholder="Email" name="email" />
              {this.state.erroremail
                  ? <span className='text-danger px-5'> {this.state.erroremail} </span>
                  : ''
                }
              </Col>
            </Row> 
            <Row className="Footerbox">
              <Col xl={6} lg={6} md={6}>
              <input placeholder="Fonction" name="fonction" />
              {this.state.errorfonction
                  ? <span className='text-danger'> {this.state.errorfonction} </span>
                  : ''
                }
              </Col>
              <Col xl={6} lg={6} md={6}>
              <input placeholder="Ville" name="ville" />
              {this.state.errorville
                  ? <span className='text-danger'> {this.state.errorville} </span>
                  : ''
                }
              </Col>
            </Row> 
            <Row className="Footerbox">
              <Col xl={12} lg={12} md={12} className="text-center">
              <button className="btn-envoyer w-80 ourSurvice">Réserver votre place</button>
              </Col>
            </Row>
           
          </Container>
          </div>
          </form>
        
        </div>
    );
  }
}
export default Banner;