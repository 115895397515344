import React,{useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        errorfullname: '',
        errorphone: '',
        erroremail: '',
        errorfonction: '',
        errorville: '',
        success:''

    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({errorfullname: ''});
    this.setState({errorphone: ''});
    this.setState({erroremail: ''});
    this.setState({errorfonction: ''});
    this.setState({errorville: ''});
    //Validation 
    if(target.nomcomplet.value ==''){
      return this.setState({errorfullname: 'nom complet est obligatoire'});
    }
    if(target.fonction.value ==''){
      return this.setState({errorfonction: 'fonction est obligatoire'});
    }
    if(target.phone.value ==''){
      return this.setState({errorphone: 'téléphone est obligatoire'});
    }
    if(target.email.value ==''){
      return this.setState({erroremail: 'email est obligatoire'});
    }
   
    if(target.ville.value ==''){
      return this.setState({errorville: 'Ville est obligatoire'});
    }
        const lead={
        'fullname':target.nomcomplet.value,
        'phone':target.phone.value,
        'email':target.email.value,
        'fonction':target.fonction.value,
        'ville':target.ville.value,
      } 
      this.setState({success: 'Merci de nous avoir contacté, nous avons bien reçu vos informations'});
      target.reset();
      window && window.dataLayer && window.dataLayer.push({
        'event':'form_submit_success',
        'event_id': Math.random().toString(36).substr(2, 9) + '' + Math.random().toString(36).substr(2, 9) +'' + Math.random().toString(36).substr(2, 9),
    });
  }
  render() {
    return (
      <div className="aboutUsBannerImg" id="contact">
      <div className="container">
        <div className="contactUs">
          <Row>
            <Col xl={6} md={6} lg={6} sm={12}>
              <div className="titleFont">
                <h3>Contactez nous</h3>
                <h2>Je suis intéressé</h2>
              </div>
              <form className="row g-3 needs-validation formContactus" method="POST" onSubmit={this.handleSubmit}>
                <div className="col-md-12 col-sm-12 col-12">
                {this.state.success
                  ? <div className='text-success px-5 text-center fs-3'> {this.state.success} </div>
                  :
                  ''
                }
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    name="nomcomplet"
                    placeholder="Nom & Prénom*"
                  />
                  {this.state.errorfullname
                  ? <span className='text-danger px-1'> {this.state.errorfullname} </span>
                  : ''
                }
                </div>

                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    name="fonction"
                    placeholder="Fonction*"
                    
                  />
                     {this.state.errorfonction
                  ? <span className='text-danger px-1'> {this.state.errorfonction} </span>
                  : ''
                }
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    placeholder="Téléphone*"
                    name="phone"
                    
                  />
                  {this.state.errorphone
                  ? <span className='text-danger px-1'> {this.state.errorphone} </span>
                  : ''
                }
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    placeholder="Email*"
                    name="email"
                  />
                   {this.state.erroremail
                  ? <span className='text-danger px-1'> {this.state.erroremail} </span>
                  : ''
                }
                </div>  
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    placeholder="Ville*"
                    name="ville"
                  />
                   {this.state.errorville
                  ? <span className='text-danger px-1'> {this.state.errorville} </span>
                  : ''
                }
                </div>
                

                <div className="col-12 text-center">
                <button className="ourSurvice w-80">Réserver votre place </button>
                </div>
              </form>
            </Col>
            <Col xl={6} md={6} lg={6} sm={12}>
              <div className="quickContact py-5">
                <h6 className="fw-bold titlecontact">Public concerné :</h6>
                <ul>
                  <li>Responsables ou acteurs del’amélioration continue</li>
                  <li>Techniciens et ingénieurs qualité, méthodes, 
                      industrialisation
                  </li>
                  <li>Responsables d’unités opérationnelles, chefs de projets,
                    consultants ou cadres dans des entreprises de services ou
                    industrielles
                  </li>
                </ul>
                <h6 className="fw-bold titlecontact">Pré-requis : </h6>
                <ul>
                  <li>Aucune connaissance techniquenécessaire.</li>
                  <li>Connaissances de base en statistiques</li>
                  <li>Utilisation des outils bureautique standards (tableur type 
                        Excel, présentation type PowerPoint) 
                  </li>
                  <li>Disposer de deux ans d’expérience professionnelle et avoir 
                      participé à des projets d’amélioration continue.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    );
  }
}

export default Banner;