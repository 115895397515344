import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Mentions légales & Conditions d’utilisation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <br />
        <p>
          CentraleSupélec vous remercie pour l’intérêt que vous portez aux
          institutions les constituant à savoir :{" "}
        </p>
        <p>
          CentraleSupélec & Centrale Casablanca, présentent sur la toile à
          travers http://www.centrale-casablanca.ma/
        </p>
        <h4>Limites de responsabilité</h4>
        <p>
          Les informations mises à disposition sur ce site le sont uniquement à
          titre purement informatif. En conséquence, l'utilisation des
          informations et contenus disponibles sur l'ensemble du site, ne
          sauraient en aucun cas engager la responsabilité de CentraleSupélec, à
          quelque titre que ce soit. L'utilisateur est seul maître de la bonne
          utilisation, avec discernement et esprit, des informations mises à sa
          disposition.
        </p>
        <p>
          {" "}
          CentraleSupélec ne pourra en aucun cas, dans la limite du droit
          applicable, être tenu responsable des dommages et/ou préjudices,
          directs ou indirects, matériels ou immatériels, ou de quelque nature
          que ce soit, résultant d'une indisponibilité du Service ou de toute
          utilisation du Service. Le terme « utilisation » doit être entendu au
          sens large.{" "}
        </p>
        <p>
          L'utilisateur s'engage, d'une manière générale, à respecter l'ensemble
          de la réglementation en vigueur au Maroc.
        </p>
        <h4>Protection de la vie privée </h4>
        <p>
          CentraleSupélec traite vos données personnelles conformément à sa «
          charte vie privée » qui respecte les dispositions de la loi 09-08 et
          ses textes d’application.
        </p>
        <h4>Les droits d’auteur </h4>
        <p>
          Le contenu de ce site est la propriété exclusive de CentraleSupélec.
          Son utilisation à des fins commerciales est strictement interdite.
        </p>
        <h4>Evolution du présent contrat</h4>
        <p>
          CentraleSupélec se réserve le droit de modifier les termes, conditions
          et mentions du présent contrat à tout moment. Il est ainsi conseillé à
          l'utilisateur de consulter régulièrement la dernière version des
          conditions d'utilisation disponible sur le site travers
          http://www.centrale-casablanca.ma/
        </p>
        <h4>Durée et résiliation</h4>
        <p>
          Le présent contrat est conclu pour une durée indéterminée à compter de
          l'utilisation du Service par l'utilisateur.
        </p>
        <h4>Vos données personnelles </h4>
        <p>
          Aucune information personnelle n'est collectée à votre insu. Dans une
          logique de respect de la vie privée, CentraleSupélec, propriétaire
          légale du site web http://www.centrale-casablanca.ma/ s'engage à ce
          que la collecte et le traitement d'informations personnelles soient
          effectués conformément à la loi n°09-08 du 18 février 2009 relative à
          la protection des personnes physiques à l’égard du traitement des
          données à caractère personnel. Aucune information personnelle n'est
          cédée à des tiers.
        </p>
        <p>
          Conformément aux dispositions de la loi 09-08, CentraleSupélec
          garantit à l'utilisateur un droit d'accès et de rectification, et
          d'opposition sur les données nominatives le concernant. Les
          traitements de ce site ont été déclarés à la CNDP sous le
          n°DGC-355/2020.
        </p>
        <h4>Les cookies</h4>
        <p>
          Dans le cadre de l’amélioration de ses services, des cookies pourront
          être placés sur l’ordinateur de l’utilisateur en vue de générer des
          statistiques sur l’utilisation de tous les sites internet (les pages
          les plus visitées, la fréquence d’accès au site, etc.).
        </p>
        <p>
          Les statistiques générées à l’aide de ces cookies sont conservées pour
          une durée d’une année.
        </p>
        <h4>Formulaire de contact </h4>
        <p>
          Les informations personnelles communiquées à CentraleSupélec via les
          différents formulaires disponibles en ligne sont utilisées uniquement
          pour répondre aux requêtes de l'utilisateur.
        </p>
        <p>
          Elles ne sont accessibles qu’aux services compétents de
          CentraleSupélec, ne sont communiquées à aucun tiers et sont conservées
          pendant la durée nécessaire à l’instruction des requêtes.
        </p>
        <h4>Les newsletters de CentraleSupélec</h4>
        <p>
          En communiquant l'adresse email, l'utilisateur accepte de recevoir
          régulièrement un courriel lui informant des différentes activités de
          CentraleSupélec. L'adresse électronique est conservée par
          CentraleSupélec tant que l'utilisateur consente à recevoir la
          newsletter.
        </p>
        <p>
          Chaque destinataire de la newsletter a le droit de se désabonner en
          utilisant un lien ajouté à cet effet à la fin de chaque numéro de la
          newsletter.
        </p>
        <h4>Vos CVs</h4>
        <p>
          Les Curriculum vitae communiqués à CentraleSupélec sont exploités
          exclusivement par le service des Ressources Humaines à des fins de
          recrutement. Ils sont conservés pour une durée n’excédant pas deux
          années.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={props.onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Popup() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <button class='button__footer' onClick={() => setModalShow(true)}>
        Mentions légales
      </button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default Popup;
