import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";
import PopupFooter from "./PopupFooter";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  return (
    <>
      <div className='footer'>
        <Container>
          <Row className='Footerbox'>
            <Col xl={5} lg={5} md={7}>
              <div className='F-col-1 footer-text'>
                <img
                  src={ThemeLogo}
                  alt='themeLogo'
                  className='img-fluid mx-auto WLogo'
                />
                <p>
                  Proposée en partenariat avec CentraleSupélec, dans la
                  continuité de la formation d’ingénieur, l’Executive Education
                  est destinée aux cadres supérieurs, entrepreneurs et
                  dirigeants, aspirant à développer leurs compétences
                  opérationnelles et managériales tout au long de leur carrière.
                </p>
              </div>
            </Col>

            <Col xl={5} lg={5} md={6} className='ms-auto'>
              <div className='F-col-4 footer-text'>
                <h3>Suivez-nous </h3>
                <div className='contactUsIconContain'>
                  <i className='fa fa-map-marker'></i>
                  <span>Casablanca Moroco</span>
                </div>
                <div className='contactUsIconContain'>
                  <i className='fas fa-phone-alt'></i>
                  <span>+212(0)522493507 | +212(0)660787377</span>
                </div>
                <div className='contactUsIconContain'>
                  <i className='fas fa-envelope'></i>
                  <span>mouhcine.hichy@centrale-casablanca.ma</span>
                </div>
                <ul className='list-unstyled social-icon'>
                  <li className='d-inline-block'>
                    <a
                      href='https://facebook.com/CPExecutiveEducation/'
                      target='_blank'
                      className='d-block'>
                      <i className='fab fa-facebook-f'></i>
                    </a>
                  </li>
                  <li className='d-inline-block'>
                    <a
                      href='https://ma.linkedin.com/company/centralesup%C3%A9lec-exed-campus-casablanca'
                      target='_blank'
                      className='d-block'>
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li>
                  <li className='d-inline-block'>
                    <a
                      href='https://www.instagram.com/csexed_casablanca/'
                      target='_blank'
                      className='d-block'>
                      <i className='fab fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='footer-bottom d-flex justify-content-evenly'>
        <h6 className='F-copywrite'>Copyright © 2022 . All Rights Reserved</h6>
        <PopupFooter />
      </div>
    </>
  );
};
export default Footer;
