import React from "react";
import Form from "./Form";

export default function SignUp() {
  return (
    <>
      <div className="otherPageBanner signupBanner">
        <div className="text-center title"></div>
      </div>
      <Form />
    </>
  );
}
