import React, { useEffect } from "react";
import { Button, Container,Row, Col, } from "react-bootstrap";
import img1 from "../../../assets/Images/OnlineLearing/Sigma.png";
import AOS from "aos";
import "aos/dist/aos.css";

export const Innovation = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  });
  return (
    <>
      <div className="Title my-5">
        <Container>
          <div className="Title">
            <Row className="OLearing-section">
              <Col xl={6} lg={6} md={6} sm={6}>
                <div
                  className="Online-learnig-Content text-align-center"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                  onScroll="AOS"
                >
                  <p>
                  Que vous soyez managers,chefs deprojets oucadres Qdans des entreprises de services ou industrielles, le Lean Six
                  Sigma permetderépondreàtoute problématique d’optimisation deprocessus.
                  Ces outils de pointe sont aujourd’hui déployés dans les entreprises 
                  leaders (AXA, GE, Motorola,Toyota), dont ils ont transformé la culture.
                  Notre conviction pédagogique est qu’au-delà de l’excellence académique de l’apprentissage des outils de Lean et de Six Sigma, nous 
                  nous engageons à transformer vos capacités d’animation et vous 
                  aider dans la mise en place d’une culture de progrès continu.
                  </p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6}>
                <img src={img1} alt="OLearing" className="mx-auto img-fluid" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};