import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";
import { useState } from "react";
const Header = () => {
  const [showDropdownC, setShowDropdownC] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }

  return (
    <>
      <div className="header sticky-top">
        <Navbar expand="lg" collapseOnSelect>
          <div className="container">
            <div className="inner-header">
              <Navbar.Brand href="/home">
                <img
                  src={ThemeLogo}
                  alt="themeLogo"
                  className="img-fluid mx-auto WLogo"
                />
              </Navbar.Brand>
                   <button className="ourSurvice d-none dowload-mobile">
                      <a href="#contact"> Télécharger la Brochure </a>
                    </button>
            </div>
            <div className="navDiv">
                  <Nav className="justify-content-center dowload-desktop" style={{ flex: 1 }}>
                   <button className="ourSurvice px-5">
                      <a href="#contact"> Télécharger la Brochure </a>
                    </button>
                  </Nav>
                </div>
          </div>
        </Navbar>
      </div>
    </>
  );
};
export default Header;
