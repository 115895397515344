import React,{useEffect} from "react";
import "../../../../src/theme/css/HomePage.css";
import { Row, Col } from "react-bootstrap";
import Feature1 from "../../../assets/Images/OurFeatures/Feature1.png";
import Feature2 from "../../../assets/Images/OurFeatures/Feature2.png";
import Feature3 from "../../../assets/Images/OurFeatures/Feature3.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
export default function OurFeature() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="ourFeature">
          <div className="titleFont text-center">
              <h2 className="fs-2 maincolor">Processus </h2>
              <h2 className="fs-2">de déroulement de la formation.</h2>
            </div>
          <div className="mainFeaturesCard">
            <Row>
              <Col xs={12} sm={6}>
                <div className="featuresCard"  data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature1} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3>DÉCOUVERTE COMPLÈTE DE L’AMÉLIORATION CONTINUE ET DU LEAN SIX SIGMA</h3>
                    <h6>
                    Maîtriser l’ensemble des concepts, des fondamentaux de l’amélioration continue (lean et six sigma, progrès 
                      continu,animationduprogrès); être sensibiliséà l’ensembledesoutils et pratiques lean et sixsigma.
                    </h6>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="featuresCard"  data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature2} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3>MISE EN OEUVRE EFFICACE DE L’AMÉLIORATION CONTINUE</h3>
                    <h6>
                    Devenir chef de projet amélioration continue par un approfondissement théorique des outils, de nouvelles mises 
                    ensituation et untravaildedéveloppementpersonnelpourdoper voscapacitésà animerle progrès.
                    </h6>
                    
                  </div>
                </div>
              </Col>
            
            </Row>
            <button className="homeBannerBtn ourSurvice w-80">
              <Link to="#contact">Réserver votre place</Link>
              </button>
          </div>
        </div>
      </div>
    </>
  );
}
