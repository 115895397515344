import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Row,Col } from 'react-bootstrap'
import Aos from "aos";
import "aos/dist/aos.css";
export default function Innovation() {
  useEffect(() => {
    Aos.init({ duration: 3500 });
  }, []);
    return (
        <>
        <div className='container'>
           <div className="servicesCards">
          <Row>
            
            <Col xl={6} lg={6} md={12}>
              <div className="servicesExploreSolution">
                <h2>
                  Be at the forefront of <span>innovation</span>
                </h2>
                <p>
                  We’re here to inform which tactics need funding and which are
                  drainson resources.
                </p>
                
                <button className="ourSurvice"><Link to="/service">Explore Solutions </Link></button>
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={6}>
              <div className="featuresCard" data-aos="zoom-out">
              <i className='fas fa-chart-pie'></i>
                <div className="featuresCardContain">
                  <h3>Company Management</h3>

                  <details className="details">
                    <summary className="summary">
                      <span id="open">
                        Read More
                      </span>
                      <span id="close">Less</span>
                    </summary>

                    <h6 className="maskbtnOpenText">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h6>
                  </details>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={6}>
              <div className="featuresCard" data-aos="zoom-out">
                <i className="fa fa-bar-chart"></i>
                <div className="featuresCardContain">
                  <h3>Company & Business Setup</h3>

                  <details className="details">
                    <summary className="summary">
                      <span id="open">
                        Read More
                      </span>
                      <span id="close">Less</span>
                    </summary>

                    <h6 className="maskbtnOpenText">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h6>
                  </details>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={6}>
              <div className="featuresCard" data-aos="zoom-out">
              <i className='fas fa-piggy-bank'></i>
                <div className="featuresCardContain">
                  <h3>Investment & Management</h3>

                  <details className="details">
                    <summary className="summary">
                      <span id="open">
                        Read More
                      </span>
                      <span id="close">Less</span>
                    </summary>

                    <h6 className="maskbtnOpenText">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h6>
                  </details>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={6}>
              <div className="featuresCard" data-aos="zoom-out">
              <i className='fas fa-business-time'></i>
                <div className="featuresCardContain">
                  <h3>Acquisitions Consulting</h3>

                  <details className="details">
                    <summary className="summary">
                      <span id="open">
                        Read More
                      </span>
                      <span id="close">Less</span>
                    </summary>

                    <h6 className="maskbtnOpenText">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h6>
                  </details>
                </div>
              </div>
            </Col>
  
            <Col xl={3} lg={3} md={4} sm={6} xs={6}>
              <div className="featuresCard" data-aos="zoom-out">
              <i className='fas fa-address-card'></i>
                <div className="featuresCardContain">
                  <h3>Private Placement Consulting</h3>

                  <details className="details">
                    <summary className="summary">
                      <span id="open">
                        Read More
                      </span>
                      <span id="close">Less</span>
                    </summary>

                    <h6 className="maskbtnOpenText">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h6>
                  </details>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={6}>
              <div className="featuresCard" data-aos="zoom-out">
                <i className="fa fa-globe"></i>
                <div className="featuresCardContain">
                  <h3>Strategic Consulting Services</h3>

                  <details className="details">
                    <summary className="summary">
                      <span id="open">
                        Read More
                      </span>
                      <span id="close">Less</span>
                    </summary>

                    <h6 className="maskbtnOpenText">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </h6>
                  </details>
                </div>
              </div>
            </Col>
          </Row>
        </div> 
        </div>
        </>
    )
}
