
import blogcontain1 from "../../../assets/Images/Blog/blogcontain1.jpg";
import blogcontain2 from "../../../assets/Images/Blog/blogcontain2.jpg";
import blogcontain3 from "../../../assets/Images/Blog/blogcontain3.jpg";
import blogcontain4 from "../../../assets/Images/Blog/blogcontain4.jpg";
import blogcontain5 from "../../../assets/Images/Blog/blogcontain5.jpg";
import blogcontain6 from "../../../assets/Images/Blog/blogcontain6.jpg";
import blogcontain7 from "../../../assets/Images/Blog/blogcontain7.jpg";
export const BlogData= [
    {
        id: 1,
        image: blogcontain1,
        date: "01 January 2022",
        comments:"251 comments",
        like: "596 likes",
        title: "Discussion Post",              
        contain: "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti "   
      },
      {
        id: 2,
        image: blogcontain2,
        date: "10 January 2022",
        comments:"351 comments",
        like: "916 likes",
        title: "Image Post",              
        contain: "Molestias eius illum libero dolor nobis deleniti, sint assumenda.orem ipsum dolor sit amet, consectetur adipisi cing elit. ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti "   
      },
      {
        id: 3,
        image: blogcontain3,
        date: "11 January 2022",
        comments:"151 comments",
        like: "236 likes",
        title: "Text Post",              
        contain: "Molestias eius illum libero dolor nobis deleniti consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis.  "   
      },
      {
        id: 4,
        image: blogcontain4,
        date: "10 February 2022",
        comments:"351 comments",
        like: "500 likes",
        title: "Gallery Post",              
        contain: "Consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti "   
      },
      {
        id: 5,
        image: blogcontain5,
        date: "14 February 2022",
        comments:"401 comments",
        like: "566 likes",
        title: "Helping Post",              
        contain: "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti "   
      },
      {
        id: 6,
        image: blogcontain6,
        date: "16 February 2022",
        comments:"511 comments",
        like: "996 likes",
        title: "Gallery Post",              
        contain: "Lorem ipsum dolor sit amet. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti ipsa optio nobis."   
      },
      {
        id: 7,
        image: blogcontain7,
        date: "27 February 2022",
        comments:"658 comments",
        like: "396 likes",
        title: "Secure Post",              
        contain: "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti "   
      },
     
 
];
export default BlogData;