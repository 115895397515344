import React from "react";
import Banner from "../HomePage/Banner"
import OurFeature from "../HomePage/OurFeature"
import AboutUs from '../HomePage/AboutUs'
import OurBestServices from "./OurBestServices";
import OurPricing from "./OurPricing";
import OurTeam from "./OurTeam";
import OurTestimonial from "./OurTestimonial";
import ContactUs from "./ContactUs";
import OurBlog from "./OurBlog";
import Brandlogo from "./Brandlogo";
import StartedToday from "./StartedToday";
import YearOfExperience from "./YearOfExperience";
import {Innovation} from "./Innovation";
import Chiffres from "./Chiffres";
import Brochure from "./Brochure";
export default function Home() {
  return (
    <>
      <Banner/>
      <Innovation />
      <OurBestServices/>
      <Chiffres />
      <OurTeam/>
      {/* <YearOfExperience/> */}
      <ContactUs/>  
      {/* <AboutUs/>
      <OurPricing/>
      <OurTestimonial/>
      <OurBlog/>
      <Brandlogo/>
      <StartedToday/> */}
      
    </>
  );
}
